import { postWithAuth, getWithAuth } from './http.service';
import {
  CREATE_CHECKOUT_SESSION,
  UPDATE_BILLING_PLAN,
  GET_STRIPE_UPCOMING_INVOICE,
  SCHEDULE_SUBSCRIPTION_DOWNGRADE,
  RENEW_SUBSCRIPTION,
  GET_DOWNGRADE_DIFFERENCE
} from './url.service';

export const updateBilling = (billingAccId, data) => postWithAuth(
  UPDATE_BILLING_PLAN(billingAccId),
  data
);

export const createCheckoutSession = (data) => postWithAuth(CREATE_CHECKOUT_SESSION(), data);

export const getUpcomingInvoice = (subscriptionId, newPlan) => getWithAuth(
  GET_STRIPE_UPCOMING_INVOICE(subscriptionId, newPlan)
);

export const getDowngradeDifference = (from, to) => getWithAuth(
  GET_DOWNGRADE_DIFFERENCE(from, to),
  true
);

export const scheduleDowngradePlan = (subscriptionId, data) => postWithAuth(
  SCHEDULE_SUBSCRIPTION_DOWNGRADE(subscriptionId),
  data
);

export const renewSubscription = (subscriptionId, data) => postWithAuth(
  RENEW_SUBSCRIPTION(subscriptionId),
  data
);
