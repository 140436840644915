import { useState, useEffect, useRef } from 'react';

const useCountdownTimer = () => {
  const [count, setCount] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const hasStarted = useRef(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isRunning && count > 0) {
      intervalId = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    } else if (count === 0) {
      setIsRunning(false);
    }

    return () => clearInterval(intervalId);
  }, [isRunning, count]);

  const startTimer = (initialCount: number) => {
    hasStarted.current = true;
    setCount(initialCount);
    setIsRunning(true);
  };

  const stopTimer = () => {
    setIsRunning(false);
  };

  const resetTimer = () => {
    setCount(0);
    setIsRunning(false);
  };

  const onTimerStop = (fn: () => void) => {
    if (hasStarted.current && count === 0) {
      fn();
    }
  };
  return {
    count,
    isRunning,
    startTimer,
    stopTimer,
    resetTimer,
    onTimerStop
  };
};

export default useCountdownTimer;
